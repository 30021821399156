import { FC } from "react";
import { Form, Row, Col } from "reactstrap"
import { SelectorFormGroup, ButtonForm } from "../common/components"
import { ClientData } from "./SdkSettingsService";
import { FormField } from "../common/components/form-field/FormField";

interface formParams {
    apiKeysList: ClientData[];
    deviceOsList: string[];
    expirationDate: Date;
    currentApiKey: string;
    currentDeviceOS: string;
    currentExpirationDate: Date;
    disableLicenseButtom: boolean;
    onApiKeyChange: (apiKey: string) => void;
    onDeviceOSChange: (deviceOS: string) => void;
    onExpirationDateChange: (expirationDate: string | undefined) => void;
    onExportLicense: () => void
}

const SdkSettingsForm: FC<formParams> = (form: formParams) => {
    const transformApiKeyList = () => {
        const list = form.apiKeysList?.map(c => ({
            text: c.name,
            value: c.token
        })) || [];

        if (list) {
            list.unshift({ text: "All", value: ""});
            return list;
        }
        
        return [];
    }

    const transformDeviceOsList = () => {
        const list = form.deviceOsList?.map(d => ({
            text: d,
            value: d
        })) || [];

        const index = list.findIndex(l => !!!l.text);
        
        if (index !== -1) {
            list[index].text = "None";
        }

        if (list) {
            list.unshift({ text: "All", value: "All"});
            return list;
        }
        
        return [];
    }

    return (
        <Form>
            <Row form>
                <Col sm={3}>
                    <SelectorFormGroup 
                        title="Client" 
                        id="apiKeySelect" 
                        values={transformApiKeyList()} 
                        onChange={(value) => form.onApiKeyChange(value)} 
                        value={form.currentApiKey} 
                    />
                </Col>
                <Col sm={2}>
                    <SelectorFormGroup 
                        title="DeviceOS" 
                        id="deviceOsSelect" 
                        values={transformDeviceOsList()} 
                        onChange={(value) => form.onDeviceOSChange(value)} 
                        value={form.currentDeviceOS}
                    />
                </Col>
                <Col sm={2}>
                    <FormField
                            label="Expiry Date"
                            type="date"
                            onChange={(value) => form.onExpirationDateChange(value)} 
                            initialValue={form.currentExpirationDate.toString()}
                            required/>
                </Col>
                <Col sm={2} className="align-self-end col-md-auto">
                    <ButtonForm 
                        text="License" 
                        importance="primary" 
                        onClick={() => { form.onExportLicense() }}
                        disabled={form.disableLicenseButtom}/>
                </Col>                 
            </Row>
        </Form>
    )
};

export default SdkSettingsForm;
