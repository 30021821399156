import axios from "../common/api/custom-axios";
import configs from "../configs";

export enum SdkSettingsType {
    String = "String",
    Integer = "Integer",
    Decimal = "Decimal",
    Boolean = "Boolean"
}

export interface FiltersData {
    apiKey: ClientData[],
    deviceOS: string[],
    expirationDate: Date
}

export interface ClientData {
    token: string,
    name: string,
}

export interface SdkSetting {
    id: number,
    apiKey: string,
    feature: string,
    key: string,
    deviceOS: string,
    description: string,
    value: string,
    type: SdkSettingsType,
}

const getFilters = async (): Promise<FiltersData> => {
    const res = await axios.get<FiltersData>(`${configs.endpoint}/sdk-settings/filters`);
    return res.data;
};

const queryData = async (apiKey?: string, deviceOS?: string): Promise<SdkSetting[]> => {
    const res = await axios.get<SdkSetting[]>(`${configs.endpoint}/sdk-settings`, {
        params: {
            apiKey,
            deviceOS
        }
    });

    return res.data;
}

const updateSetting = async (setting: SdkSetting): Promise<void> => {
    await axios.put(`${configs.endpoint}/sdk-settings/${setting.id}`, {
        value: setting.value
    });
}

const service = {
    getFilters,
    queryData,
    updateSetting
}

export default service;