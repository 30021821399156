import { FC, useState } from "react";
import { Button, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ApiSetting, ApiSettingsType } from "./ApiSettingsService";

interface apiSettingsEditModalProps {
    setting: ApiSetting;
    onClose: () => void;
    onUpdate: (setting: ApiSetting) => any;
}

const ApiSettingsEditModal: FC<apiSettingsEditModalProps> = (modalProps: apiSettingsEditModalProps) => {
    const { setting, onClose, onUpdate } = modalProps;
    const [value, setValue] = useState<string>(setting.value);

    const isUpdateButtonEnabled = () => value !== setting.value;

    const countDecimals = (num: number) => {
        if (Math.floor(num) !== num) {
            return num.toString().split(".")[1].length || 0;
        }

        return 0;
    }

    const numberFormField = (initialValue: number, onChange: (e: string) => void, decimalValues: number = 0, maxLength: number = 4) => (
        <FormGroup>
            <Label for="numberFormInput">Value</Label>
            <Input
                step={1 / Math.pow(10, decimalValues)}
                type="number"
                name="booleanFormInput"
                defaultValue={initialValue}
                onChange={e => {
                    if (e.target.value.length <= maxLength) {
                        onChange(e.target.value)
                    }
                }}
                onInput={e => {
                    const target = e.target as HTMLInputElement;
                    if (target.value.length > maxLength) {
                        target.value = target.value.slice(0, maxLength);
                    }
                }}               
            />
        </FormGroup>
    );

    const booleanFormField = (initialValue: boolean, onChange: (e: string) => void) => (
        <FormGroup>
            <Label for="booleanFormInput">Value</Label>
            <Input 
                type="select"
                name="booleanFormInput" 
                id="booleanFormInput" 
                defaultValue={initialValue.toString()}
                onChange={e => {onChange(e.target.value)}}
            >
                <option>true</option>
                <option>false</option>
          </Input>
        </FormGroup>
    );

    const stringFormField = (initialValue: string, onChange: (e: string) => void) => (
        <FormGroup>
            <Label for="stringFormInput">Value</Label>
            <Input
                plaintext
                name="stringFormInput"
                defaultValue={initialValue}
                onChange={e => {onChange(e.target.value)}}
            />
        </FormGroup>
    );

    const buildFormField = (data: ApiSetting) => {
        if (data.type === ApiSettingsType.Integer) {
            const numberValue = parseInt(data.value);
            return numberFormField(numberValue, (e) => setValue(e));
        }

        if (data.type === ApiSettingsType.Decimal) {
            const floatValue = parseFloat(data.value);
            return numberFormField(floatValue, (e) => setValue(e), countDecimals(floatValue));
        }

        if (data.type === ApiSettingsType.Boolean) {
            const booleanValue = (data.value === "true");
            return booleanFormField(booleanValue, (e) => setValue(e));
        }

        return stringFormField(data.value, (e) => setValue(e));
    }

    return (
        <Modal isOpen={true} toggle={onClose} size="md">
            <ModalHeader toggle={onClose}>Edit {setting.key}</ModalHeader>
            <ModalBody>
                <Container>
                    <Form>
                        {buildFormField(setting)}
                    </Form>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="primary" 
                    onClick={() => {
                        onUpdate({ ...setting, value });
                        onClose();
                    }}
                    disabled={!isUpdateButtonEnabled()}>
                        Update
                </Button>
                <Button color="secondary" onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ApiSettingsEditModal;