import axios from "../common/api/custom-axios";
import configs from "../configs";

export interface License { license: string }

const getLicense = async (apiKey: string, deviceOS: string, expirationDate: Date): Promise<License> => {
    const query = `deviceOS=${deviceOS}&expirationDate=${expirationDate.toISOString()}`;
    const promise = await axios.get<License>(`${configs.endpoint}/sdk-license/${apiKey}?${query}`);

    return promise.data;
}

const service =  {
    getLicense
}

export default service;