/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import ToastService from "../common/services/toast-service";
import SdkSettingsService, { FiltersData, SdkSetting } from "./SdkSettingsService";
import SdkSettingsForm from "./SdkSettingsForm";
import SdkSettingsTable from "./SdkSettingsTable";
import SdkLicenseConfigurationService, { License } from './SdkLicenseConfigurationService';

export interface IFilters {
    apiKey: string;
    deviceOS: string;
    expirationDate: Date;
}

const SdkSettings = () => {

    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

    const [filters, setFilters] = useState<FiltersData>({ apiKey: [], deviceOS: [], expirationDate: new Date() });
    const [filtersToQuery, setFiltersToQuery] = useState<IFilters>({ apiKey: "", deviceOS: "All", expirationDate: expirationDate });
    const [data, setData] = useState<SdkSetting[]>([]);
    const [disableLicenseButtom, setDisableLicenseButtom] = useState({ status: true });
    const [license, setLicense] = useState<License | null>();

    const getData = async () => {
        try {
            const data = await SdkSettingsService.queryData(filtersToQuery.apiKey, filtersToQuery.deviceOS);
            setData(data);
        } catch (error: any) {
            ToastService.showToast("Error getting queried data", error.message);
        }
    }

    const getFilters = async () => {
        try {
            const data = await SdkSettingsService.getFilters();
            setFilters(data);
        } catch (error: any) {
            ToastService.showToast("Error getting filters", error.message);
        }
    }

    useEffect(() => {
        getFilters();
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [filtersToQuery]);

    useEffect(() => { setDisableLicenseButtom({ status: filtersToQuery.apiKey === "" || filtersToQuery.deviceOS === "All" }) }, [filtersToQuery])

    const onSettingUpdate = async (setting: SdkSetting) => {
        try {
            await SdkSettingsService.updateSetting(setting);
            await getData();
            ToastService.showToast(setting.key, "Setting updated successfully", true);
        } catch (error: any) {
            let errorData = error.response.data.detail ? error.response.data.detail : error.message;
            ToastService.showToast("Error updating setting", errorData);
        }
    }

    const onExportLicense = async () => {

        try {
            var license = await SdkLicenseConfigurationService
                                .getLicense(filtersToQuery.apiKey, 
                                            filtersToQuery.deviceOS, 
                                            filtersToQuery.expirationDate);

            setLicense(license);
        } catch(error: any) {
            let errorData = error.response.data.instance ? error.response.data.instance : error.message;
            ToastService.showToast("Generating license", errorData);
        }
    }

    const onToggle = () => setLicense(null);

    const copyToCloboard = async () => {

        onToggle();
        await navigator.clipboard.writeText(license!.license);
    }

    return (
        <Container>
            <Container>
                <SdkSettingsForm
                    apiKeysList={filters.apiKey}
                    deviceOsList={filters.deviceOS}
                    expirationDate={filters.expirationDate}
                    currentApiKey={filtersToQuery.apiKey}
                    currentDeviceOS={filtersToQuery.deviceOS}
                    currentExpirationDate={filtersToQuery.expirationDate}
                    disableLicenseButtom={disableLicenseButtom.status}
                    onApiKeyChange={(value) => setFiltersToQuery(s => ({
                        ...filtersToQuery,
                        apiKey: value
                    }))}
                    onDeviceOSChange={(value) => setFiltersToQuery(s => ({
                        ...filtersToQuery,
                        deviceOS: value
                    }))}
                    onExpirationDateChange={(value) => setFiltersToQuery(s => ({
                        ...filtersToQuery,
                        expirationDate: new Date(value!.toString())
                    }))}
                    onExportLicense={onExportLicense}
                />
            </Container>
            {data ? <SdkSettingsTable settings={data} onSettingUpdate={onSettingUpdate} /> : <Spinner color="primary" />}
            <Modal isOpen={license != null} toggle={() => {onToggle()}} fade={true} size="lg">
                <ModalHeader>JWT License</ModalHeader>
                <ModalBody style={{ overflowWrap: 'anywhere' }}>
                    {license?.license}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {copyToCloboard()}}>Copy</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
};

export default SdkSettings;
